import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './pages/mainPage/mainPage';
import Courses from './pages/coursesPage/Courses';
import СourseMladenets from './pages/СourseMladenets/СourseMladenets';

function App() {
  return (
    <div className='appWrapper'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/courses' element={<Courses />} />
          <Route path='/courses/vse_o_mladentse' element={<СourseMladenets />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
