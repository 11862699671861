import {useRef, useState, useEffect} from "react"
import s from './Courses.module.css';
import Navbar from "../../components/Navbar/navbar"
import Footer from "../../components/Footer/Footer"
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { BurgerMenu } from "../../components/BurgerMenu"
import Reveal from "../../components/Reveal/Reveal";
import {ReactComponent as Lightning} from "../../assets/lightning.svg";
import VseOMladentse from './assets/VseOMladentse.png'

const Courses = () => {

    const { height, width } = useWindowDimensions();

    const [showBurgerMenu, setShowBurgerMenu] = useState(false)

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if(width && width < 700){
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width])

    return(
        <div className={s.pageWrapper}>
            <Navbar showBurgerMenu={showBurgerMenu} type={'courses'} setShowBurgerMenu={setShowBurgerMenu} />

            <BurgerMenu setIsOpen={setShowBurgerMenu} isOpen={showBurgerMenu}> 
                <div className={s.burgerLinks}>
                    <a href="/#about" onClick={() => setShowBurgerMenu(false)} >О нас</a>
                    <a href="/#services" onClick={() => setShowBurgerMenu(false)} >Услуги</a>
                    <a href="/#doctors" onClick={() => setShowBurgerMenu(false)} >Наши врачи</a>
                    <a href="/#programs" onClick={() => setShowBurgerMenu(false)} >Программы</a>
                    <a href="/#reviews" onClick={() => setShowBurgerMenu(false)} >Отзывы</a>
                </div>
            </BurgerMenu>

            <div className={s.heroWrapper}>
                <p className={s.subtitle}><Lightning />&nbsp; 400+ студентов уже прменили на практике</p>
                <Reveal>
                    <h1 className={s.mainTitle}>Наши курсы</h1>
                </Reveal>
            </div>

            <div className={s.coursesList}>
                <div className={s.course} style={{background: '#CBEB96'}}>
                    <img className={s.courseImage} src={VseOMladentse}/>
                    <div className={s.courseInfo}>
                        <h2>Всё о младенце</h2>
                        <p>Честные ответы на реальные вопросы и жизненные ситуации.<br />
                        Разберем все вопросы от развития малыша - до здоровья!
                        </p>
                        <a className={s.button}>Подробнее</a>
                    </div>
                </div>
                <div className={s.course} style={{background: '#B1D5F7'}}>
                    <img className={s.courseImage} src={VseOMladentse}/>
                    <div className={s.courseInfo}>
                        <h2>Всё о младенце</h2>
                        <p>Честные ответы на реальные вопросы и жизненные ситуации.<br />
                        Разберем все вопросы от развития малыша - до здоровья!
                        </p>
                        <a className={s.button}>Подробнее</a>
                    </div>
                </div>
                <div className={s.course} style={{background: '#F7B458'}}>
                    <img className={s.courseImage} src={VseOMladentse}/>
                    <div className={s.courseInfo}>
                        <h2>Всё о младенце</h2>
                        <p>Честные ответы на реальные вопросы и жизненные ситуации.<br />
                        Разберем все вопросы от развития малыша - до здоровья!
                        </p>
                        <a className={s.button}>Подробнее</a>
                    </div>
                </div>
            </div>

            <div className={s.coursesList}>
                <div className={s.course} style={{background: '#F7B458'}}>
                    <img className={s.courseImage} src={VseOMladentse}/>
                    <div className={s.courseInfo}>
                        <h2>Всё о младенце</h2>
                        <p>Честные ответы на реальные вопросы и жизненные ситуации.<br />
                        Разберем все вопросы от развития малыша - до здоровья!
                        </p>
                        <a className={s.button}>Подробнее</a>
                    </div>
                </div>
                <div className={s.course} style={{background: '#CBEB96'}}>
                    <img className={s.courseImage} src={VseOMladentse}/>
                    <div className={s.courseInfo}>
                        <h2>Всё о младенце</h2>
                        <p>Честные ответы на реальные вопросы и жизненные ситуации.<br />
                        Разберем все вопросы от развития малыша - до здоровья!
                        </p>
                        <a className={s.button}>Подробнее</a>
                    </div>
                </div>
                <div className={s.course} style={{background: '#B1D5F7'}}>
                    <img className={s.courseImage} src={VseOMladentse}/>
                    <div className={s.courseInfo}>
                        <h2>Всё о младенце</h2>
                        <p>Честные ответы на реальные вопросы и жизненные ситуации.<br />
                        Разберем все вопросы от развития малыша - до здоровья!
                        </p>
                        <a className={s.button}>Подробнее</a>
                    </div>
                </div>
                
            </div>
  
            <Footer />
        </div>
        
    )
}

export default Courses; 