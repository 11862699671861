import {useRef, useState, useEffect} from "react"
import s from './Course.module.css';
import Navbar from "../../components/Navbar/navbar"
import Footer from "../../components/Footer/Footer"
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { BurgerMenu } from "../../components/BurgerMenu"

import { ReactComponent as BackgroundCircle } from "./assets/backgroundCircle.svg"
import { ReactComponent as BackgroundBlackStar } from "./assets/backgroundBlackStar.svg"
import { ReactComponent as BackgroundStar } from "./assets/backgroundStar.svg"

import TRimage from "../../assets/TR.png";
import ARimage from "../../assets/AR.png";
import TSimage from "../../assets/TS.png";
import { ReactComponent as ChevronRight } from "../../assets/chevron_right.svg"
import { ReactComponent as ChevronRightSmall } from "../../assets/chevron_right_small.svg"
import { ReactComponent as ChevronLeftSmall } from "../../assets/chevron_left_small.svg"
import { ReactComponent as FirstMessage } from "../../assets/firstMessage.svg"
import { ReactComponent as SecondMessage } from "../../assets/secondMessage.svg"

import { ReactComponent as On_course_1 } from "./assets/on_course_1.svg"
import { ReactComponent as On_course_2 } from "./assets/on_course_2.svg"
import { ReactComponent as On_course_3 } from "./assets/on_course_3.svg"
import { ReactComponent as On_course_4 } from "./assets/on_course_4.svg"
import { ReactComponent as On_course_5 } from "./assets/on_course_5.svg"

import { ReactComponent as How_edu_1 } from "./assets/how_edu_1.svg"
import { ReactComponent as How_edu_2 } from "./assets/how_edu_2.svg"
import { ReactComponent as How_edu_3 } from "./assets/how_edu_3.svg"

import { ReactComponent as On_course_star } from "./assets/on_course_star.svg"
import Hero1 from "./assets/hero1.png"
import Hero2 from "./assets/hero2.png"

const СourseMladenets = () => {

    const { height, width } = useWindowDimensions();

    const [showBurgerMenu, setShowBurgerMenu] = useState(false)

    const [isMobile, setIsMobile] = useState(false)

    const [doctorIndex, setDoctorIndex] = useState(1)
    const [doctorsSwitcherClassName, setDoctorsSwitcherClassName] = useState(`${s.doctorImage}`)

    useEffect(() => {
        if(width && width < 700){
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width])

    const switchDoctor = () => {
        setDoctorsSwitcherClassName(`${s.doctorImage} ${s.doctorSwitched}`)
        setTimeout(() => {
            setDoctorsSwitcherClassName(`${s.doctorImage}`)
        }, 1000)
    }

    const doctorForward = () => {
        if(doctorIndex === 3){
            setDoctorIndex(1)
        } else {
            setDoctorIndex(doctorIndex+1)
        }
        switchDoctor()
    }

    const doctorBackward = () => {
        if(doctorIndex === 1){
            setDoctorIndex(3)
        } else {
            setDoctorIndex(doctorIndex-1)
        }
        switchDoctor()
    }

    return(
        <div className={s.pageWrapper}>
            <Navbar showBurgerMenu={showBurgerMenu} setShowBurgerMenu={setShowBurgerMenu} />

            <BurgerMenu setIsOpen={setShowBurgerMenu} isOpen={showBurgerMenu}> 
                <div className={s.burgerLinks}>
                    <a href="#about" onClick={() => setShowBurgerMenu(false)} >О нас</a>
                    <a href="#services" onClick={() => setShowBurgerMenu(false)} >Услуги</a>
                    <a href="#doctors" onClick={() => setShowBurgerMenu(false)} >Наши врачи</a>
                    <a href="#programs" onClick={() => setShowBurgerMenu(false)} >Программы</a>
                    <a href="#reviews" onClick={() => setShowBurgerMenu(false)} >Отзывы</a>
                </div>
            </BurgerMenu>

            <div className={s.heroBlockWrapper}>
                <div className={s.heroBlock}>
                    <div className={s.heroMain}>
                        <div className={s.headingWrapper}>
                            <h1>Курс «Всё<br />о младенце»</h1>
                            <p>Честные ответы на реальные вопросы и жизненные ситуации.</p>
                            <p>Стоимость курса: 5000 ₽ <span>7999 ₽</span></p>
                        </div>
                        <a className={s.heroButton} href="http://pay.zdravieschool.ru/buy/kurs-ya-rodila-tarif-ya-s-avtorom" target="_blank">Записаться на курс</a>
                    </div>
                    <div className={s.heroSecondary}>
                        <div className={s.heroImageWrapper}>
                            <img src={Hero1} />
                        </div>
                        <div className={s.heroImageWrapper}>
                            <img src={Hero2} />
                        </div>
                    </div>
                </div>

                <div className={s.secondaryBlock}>
                    <h2 className={s.secondaryText}>Вы узнаете все о первых месяцах жизни малыша!</h2>
                    <h2 className={s.secondaryTextHidden}>Вы узнаете все о первых месяцах жизни малыша!</h2>
                    <BackgroundCircle className={s.backgroundCircle}/>
                    <BackgroundBlackStar className={s.backgroundBlackStar}/>
                    <BackgroundStar className={s.backgroundStar}/>
                </div>
            </div>

            <div className={s.lightSection}>
                <h1>Что будет на курсе</h1>
                <div className={s.cardsGrid}>
                    <div className={s.aboutCard}>
                        <On_course_1 />
                        <div className={s.aboutCardContent}>
                            <h3>Подготовка</h3>
                            <p>Рассмотрим, что на самом деле нужно приготовить к рождению малыша, исходя из практического опыта.</p>
                        </div>
                    </div>
                    <div className={s.aboutCard}>
                        <On_course_2 />
                        <div className={s.aboutCardContent}>
                            <h3>Новая жизнь</h3>
                            <p>Подробно изучим информацию о первых неделях жизни малютки, ведь огромное количество вопросов возникает сразу, буквально, в его день рождения.</p>
                        </div>
                    </div>
                    <div className={s.aboutCard}>
                        <On_course_3 />
                        <div className={s.aboutCardContent}>
                            <h3>Материнство</h3>
                            <p>Раскроем все секреты счастливого материнства, применим на практике советы и хитрости, которые уже проверены не одним десятком малышей.</p>
                        </div>
                    </div>
                    <div className={s.aboutCard}>
                        <On_course_4 />
                        <div className={s.aboutCardContent}>
                            <h3>Здоровье</h3>
                            <p>Поговорим о вопросах прививок и вакцинации.</p>
                        </div>
                    </div>
                    <div className={s.aboutCard}>
                        <On_course_5 />
                        <div className={s.aboutCardContent}>
                            <h3>Уверенность</h3>
                            <p>Гарантировано поможем обрести уверенность в своих силах, а это крайне важно для молодой мамы.</p>
                        </div>
                    </div>
                    <div className={s.bigCard}>
                        <h1>Разберем все вопросы от развития - до здоровья</h1>
                        <On_course_star className={s.bigCardStar}/>
                    </div>
                </div>
                <a className={s.fullWidthButton} href="http://pay.zdravieschool.ru/buy/kurs-ya-rodila-tarif-ya-s-avtorom" target="_blank">Записаться на курс</a>
            </div>

            <div className={s.colorSection}>
                <h1>Преимущества курса</h1>
                <div className={s.cardsGrid}>
                    <div className={s.colorCard}>
                        <p>Вам не нужно тратить драгоценное время на поиск информации.</p>
                    </div>
                    <div className={s.colorCard}>
                        <p>С нами вы никогда не узнаете как счастливое материнство из-за неправильных советов и потерянного времени может превратиться в горький опыт.</p>
                    </div>
                    <div className={s.colorCard}>
                        <p>Мы собрали ВСЕ САМЫЕ НЕОБХОДИМЫЕ темы, чтобы первый год маленького и хрупкого человечка прошел УСПЕШНО.</p>
                    </div>
                </div>
                <a className={s.fullWidthButton} href="http://pay.zdravieschool.ru/buy/kurs-ya-rodila-tarif-ya-s-avtorom" target="_blank">Записаться на курс</a>
            </div>

            <div className={s.lightSection}>
                <h1>Как проходит обучение</h1>
                <div className={s.cardsGrid}>
                    <div className={s.halfColorCard}>
                        <How_edu_1 />
                        <div className={s.aboutCardContent}>
                            <h3>Все уроки онлайн</h3>
                            <p>Все занятия проходят онлайн, доступ к урокам сохраняется в течение 30 дней. Не нужно никуда ехать, только доступ в интернет.</p>
                        </div>
                    </div>
                    <div className={s.halfColorCard}>
                        <How_edu_2 />
                        <div className={s.aboutCardContent}>
                            <h3>Мы поможем вам</h3>
                            <p>Наши преподаватели и специалисты тех. поддержки всегда ответят на любые ваши вопросы во время прохождения курса.</p>
                        </div>
                    </div>
                    <div className={s.halfColorCard}>
                        <How_edu_3 />
                        <div className={s.aboutCardContent}>
                            <h3>Понятно и практично</h3>
                            <p>Без воды и сложной терминологии. Доступно людям без медицинского образования. Вы получите только самую нужно информацию и сможете сразу применить знания на практике.</p>
                        </div>
                    </div>
                </div>
                <a className={s.fullWidthButton} href="http://pay.zdravieschool.ru/buy/kurs-ya-rodila-tarif-ya-s-avtorom" target="_blank">Записаться на курс</a>
            </div>

            <div className={s.ourDoctors} id="doctors">
                <div className={s.aboutDoctor}>
                    <div className={s.aboutDoctorHeading}>
                        <h1>Специалисты курса</h1>
                        <div className={s.aboutDoctorsSwitcher}>
                            <div onClick={doctorBackward} className={s.switcherButton}>
                                <ChevronLeftSmall />
                            </div>
                            <div onClick={doctorForward} className={s.switcherButton}>
                            <ChevronRightSmall />
                            </div>
                        </div>
                    </div>
                        
                    <div className={s.messages}>
                        <div className={s.firstMessage}>
                            Кто в команде курса?
                            <FirstMessage className={s.firstMessageCorner} />
                        </div>
                        <div className={s.doctorMessageWrapper}>
                            {
                                doctorIndex === 1 ? 
                                    <div className={s.doctorMessage}>
                                        <p className={s.doctorName}>Татьяна Растегаева</p>
                                        <p className={s.doctorMessageText}>
                                        Врач педиатр, гомеопат, натуропат,<br/>
                                        нутрициолог - диетолог, врач интегративной,<br/>
                                        превентивной и антивозрастной медицины,<br/>
                                        висцеральный терапевт, специалист<br/>
                                        по массажу.</p>
                                        <SecondMessage className={s.secondMessageCorner}/>
                                    </div>
                                :
                                doctorIndex === 2 ?
                                    <div className={s.doctorMessage}>
                                        <p className={s.doctorName}>Алексей Растегаев</p>
                                        <p className={s.doctorMessageText}>
                                        Мануальный терапевт, остеопат.<br/>
                                        Специалист по лечению вертебральной патологии<br/>
                                        у взрослых и детей. Лечение головных и суставных<br/>
                                        болей, авторские методики. Врач превентивной,<br/>
                                        интегративной и антивозрастной медицины.<br/>
                                        Студент PreventAge Basic.</p>
                                        <SecondMessage className={s.secondMessageCorner}/>
                                    </div>
                                :
                                    <div className={s.doctorMessage}>
                                        <p className={s.doctorName}>Татьяна Сиротина</p>
                                        <p className={s.doctorMessageText}>
                                        Врач-педиатр. Специалист по реабилитации<br/>
                                        детей раннего возраста и детей с задержкой<br/>
                                        речевого развития. Специалист по массажу.<br/>
                                        Висцеральный терапевт. Специалист по<br/>
                                        дыхательной гимнастике Стрельниковой.
                                        </p>
                                        <SecondMessage className={s.secondMessageCorner}/>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className={s.ourDoctorsText}>
                    <p>Курс создан опытными специалистами,<br />работащими с детьми более 25 лет!</p>
                        <div onClick={doctorForward} className={s.nextButton}>
                            <ChevronRight  />
                        </div>
                    </div>
                </div>
                <div className={s.doctorImageWrapper}>
                    <img className={doctorsSwitcherClassName} src={doctorIndex === 1 ? TRimage : doctorIndex === 2 ? ARimage : TSimage} />
                    <div className={s.switcher}>
                        <div onClick={doctorBackward} className={s.switcherButton}>
                            <ChevronLeftSmall />
                        </div>
                        <div onClick={doctorForward} className={s.switcherButton}>
                            <ChevronRightSmall />
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.questionsBlock}>
                <h1>Остались вопросы?</h1>
                <div className={s.questionsBlockCards}>
                    <div className={s.questionsBlockCard}>
                        <p>Пишите нам в Telegram: @Olyaaaa1</p>
                        <a href="https://t.me/Olyaaaa1" target="_blank">Написать</a>
                    </div>
                    <div className={s.questionsBlockCard}>
                        <p>Пишите нам на почту: dr_rastegaeva@mail.ru</p>
                        <a href="mailto:dr_rastegaeva@mail.ru">Написать</a>
                    </div>
                </div>
            </div>
  
            <Footer />
        </div>
        
    )
}

export default СourseMladenets; 